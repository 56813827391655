import React from 'react'
import { graphql } from 'gatsby'
import InfoPage from '../../components/info-page'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Heading from '../../components/heading'
import { ContainerLg } from '../../components/container'

function InsectsInfoPage({ data }) {
  const insects = data.info.edges[0].node

  return (
    <Layout pageTransition={false}>
      <SEO title="Info Insekten" />
      <ContainerLg>
        <Heading level={1}>Insekten</Heading>
        <InfoPage info={insects} />
      </ContainerLg>
    </Layout>
  )
}

export const query = graphql`
  query InsectsInfoPageQuery {
    info: allSanityInfo(filter: { name: { eq: "Insekten" } }) {
      edges {
        node {
          name
          _rawPestsinsects
          pestsinsects {
            _key
            title
            needForAction
            images {
              imageText
              _key
              image {
                asset {
                  metadata {
                    dimensions {
                      height
                      width
                    }
                  }

                  fluid(maxWidth: 800) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default InsectsInfoPage
